.Table {
    width: 100%;
    margin-top: 15px;
    border: none;
}

.headerRow {
    border-radius: unset;

}

.headerRow,
.evenRow,
.oddRow {
    border: none;
    border-top: none;
}

.inactiveRow {
    color: rgba(197, 189, 189, 0.95) !important;
}

.headerColumn {
    margin-left: 0;
    margin-right: 0;
}

.headerColumn:first-of-type {
    margin-left: 0;
}

.bodyColumn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 44px;
    display: flex;
    align-items: center;
}

.bodyColumn.dropdown {
    overflow: visible !important;
}
